import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import Img from "gatsby-image"
import Blobs from "../components/blobs"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./projects.css"

const About = ({ data }) => (
  <Layout>
    <SEO title="about"></SEO>
    <div className="projectMain">
      <Img className="mainImage" fluid={data.sanityAuthor.image.asset.fluid}></Img>
      <h2>{data.sanityAuthor.name}</h2>
      <BlockContent 
        blocks={data.sanityAuthor._rawBio}
        imageOptions={{w: 640, h: 640, fit: 'max'}}
      ></BlockContent>
    </div>
    <div class="paginationContainer">
      <a className="backLink" href="/" onClick={(e) => {
        e.preventDefault()
        window.history.back()
      }}
      >Back</a>
    </div>
    <Blobs className="projectBlob"></Blobs>
  </Layout>
)

export const query = graphql `
query AuthorQuery {
  sanityAuthor(name: {eq: "Annukka"}) {
    id
    name
    _rawBio
    image {
      asset {
        id
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
}
`

export default About
